/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import Slider from "react-slick"
import { css } from "@emotion/core"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Section from "../section"
import Reference from "../reference-link"

const ReferenceSection = ({ title, references, basePath }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 4000,
  }


  return (
    <div>
      <section className="float-left flow-root w-full  py-20 lg:py-32">
        <div className="float-left w-full px-4">
          <div className="max-w-4xl mx-auto">
            {title && <h2 className="text-3xl" sx={{ textAlign: "center", color: 'greenblue' }}>{title}</h2>}
            <Slider {...settings} sx={{ my: 3 }}>
              {references.map(ref => (
                <Reference key={ref.id} {...ref} />
              ))}
            </Slider>
            {/* <Link to={basePath}>See all</Link> */}
          </div>
        </div>
      </section>
    </div>
  )
}

export default ReferenceSection
